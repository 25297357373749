import React from 'react';
import { Button, Backdrop, CircularProgress } from '@material-ui/core';
import { createMuiTheme, ThemeProvider, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
//import yellow from '@material-ui/core/colors/yellow';
//import RoleSelector from './components/RoleSelector';
import Cookies from 'js-cookie';
import Welcome from './components/Welcome';
import ThreeHundredEach from './components/ThreeHundredEach'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';

let theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: {
      main: '#f9a825',
    },
    //type: 'dark'
  },
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

// Use backdrop to have the user to wait during cookie validation
// Check if we have a valid cookie, if yes redirect, if not do nothing

//import Cookies from 'js-cookie'; // t="reference of the last time keeper" p="boolean stating pro"
Cookies.get("t");

const App = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);


  return (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => {
            setLoading(false);
          }}
        >
        <CircularProgress color="secondary" />
      </Backdrop>
      <BrowserRouter>
        <CssBaseline />
        <Switch>
          <Route path="/:id">
            <Button variant="contained" color="primary">
              Hello World test3
            </Button>
          </Route>
          <Route path="/timekeeper">
            <ThreeHundredEach />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </React.Fragment>
  );
}

export default App;