import React from 'react';
//import { useState } from 'react';
import { 
  Button, 
  Box,
  Container, 
  Typography, 
  Grid, 
  Card, 
  Link,
  CardHeader, 
  CardContent, 
  CardActions, 
  Popover,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
  } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import '../logo.css';
//import Cookies from 'js-cookie'; // t="reference of the last time keeper" p="boolean stating pro"
import { makeStyles } from '@material-ui/core/styles';
//import black from '@material-ui/core/colors/yellow';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none'
    }
  },
  gridMaster: {
    flexGrow: 1,
  },
  popover: {
    pointerEvents: 'none',
  },
  autoExpire: {
    padding: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  feature: {
    lineHeight: theme.spacing(0.26)
  },
  listBack: {
    listStyle: 'disc',
    marginLeft: theme.spacing(2)
  },
  heroContent: {
    padding: theme.spacing(8, 2, 6),
    '& h1': {
      marginBottom: theme.spacing(10)
    }
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://300each.com/">
        300each.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const AutoExpire = () => {
  
  const classes = useStyles();
  return (
    <Typography>
      To offer you a great free service for everyone, we manage our ressources optimaly.<br /><br />
      Therefore <span class="logo">300each</span> will free up your team data if:
      <ul>
        <li className={classes.listBack}>you didn't run a roundtable since 2 weeks</li>
        <li className={classes.listBack}>you decide to clean-up your browser's cookie for 300each.com</li>
      </ul>
    </Typography>
  );
}

const Welcome = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [openAlert, setAlertOpen] = React.useState(false);

  const handleClickOpen = () => {
    setAlertOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };


  return (
   <React.Fragment>
    <Container maxWidth="sm" className={classes.heroContent}>
        <Typography variant="h1" align="center" gutterBottom><span className="logo">300each</span></Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>Doing a roundtable to get quick &amp; informal status update? Struggling to timebox the session or to get everybody their share of speaking time?</Typography>
        <Typography variant="h5" align="center" color="textPrimary" paragraph>Enjoy more time out of your day now!</Typography>
    </Container>
    
    <Container maxWidth="lg">
      <Grid container spacing={5} className={classes.gridMaster}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader
                    title="Kitchen timer"
                    subheader="No sign-up / no back-up"
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    Free
                  </Typography>
                </div>
                <ul>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                        Auto-expiration and browser cookie login
                        <IconButton 
                          size="small" 
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}>
                            <InfoIcon />
                        </IconButton>
                        <Popover
                          id='mouse-over-popover'
                          open={open}
                          anchorEl={anchorEl}
                          className={classes.popover}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          disableRestoreFocus
                        >
                          <Box className={classes.autoExpire}>
                          <AutoExpire /></Box>
                        </Popover>
                      </Typography>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                      Start session only
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                      2 observers max.
                    </Typography>
                </ul>
              </CardContent>
              <CardActions>
                <Button fullWidth variant="outlined" color="secondary" onClick={handleClickOpen}>
                  Start free
                </Button>
                <Dialog
                  open={openAlert}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Do you agree to use auto-expiration?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <AutoExpire />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Disagree
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader
                    title="Olympic stopwatch"
                    subheader="Control and comfort"
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    € 4.99
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /year
                  </Typography>
                </div>
                <ul>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                      No expiration and e-mail login
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                      Pause, re-order and custom durations
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                      Unlimited observers with permalink 
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" className={classes.feature}>
                      Team analytics
                    </Typography>
                </ul>
              </CardContent>
              <CardActions>
                <Button fullWidth variant="contained" color="secondary">
                  Sign-up or sign-in
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
   </React.Fragment>
  );
  }

export default Welcome;